import { CHECKBOX_LEF, TEXT_INPUT, WYSIWYG_EDITOR } from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'set-order-transfer-orders'
  const MODULE_NAME_CLONE = 'cloneData'
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const transferOrdersFields = {
    transfer_orders_min_time: {
      type: TEXT_INPUT,
      label: 'Minimum Transit Time (minutes)',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    transfer_orders_schedule_after_warehouse_open: {
      type: TEXT_INPUT,
      label: 'Schedule transfers after warehouse opens (minutes)',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    transfer_orders_schedule_before_warehouse_closes: {
      type: TEXT_INPUT,
      label: 'Schedule transfers before warehouse closes (minutes)',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    transfer_orders_max_round_trips_in_day: {
      type: TEXT_INPUT,
      label: 'Maximum round trips in a day',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    transfer_orders_turnaround: {
      type: CHECKBOX_LEF,
      placeholder: 'Turnaround',
      label: 'Transfer require inspection on arrival',
      rules: 'required',
    },
    transfer_orders_order_scheduling: {
      type: CHECKBOX_LEF,
      placeholder: 'Order Scheduling',
      label: 'Allow adding items to existing transfer orders',
      rules: 'required',
    },
    transfer_orders_order_picking: {
      type: CHECKBOX_LEF,
      placeholder: 'Order Picking',
      label: 'Allow adding items after pick and before dispatch',
      rules: 'required',
    },
    transfer_orders_templates_email: {
      type: WYSIWYG_EDITOR,
      label: 'Email',
      rules: 'required',
      options: {
        class: 'text-dark',
        rows: 8,
      },
    },
    transfer_orders_templates_pdf: {
      type: WYSIWYG_EDITOR,
      label: 'PDF version',
      rules: 'required',
      options: {
        class: 'text-dark',
        rows: 8,
      },
    },
  }
  return {
    transferOrdersFields,
    ACCESS_ABILITY_SETTINGS_ORDERS,
    MODULE_NAME,
    MODULE_NAME_CLONE,
  }
}
