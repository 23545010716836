<template>
  <div>
    <skeleton v-if="loading" />
    <transfer-orders-form
      v-else
      ref="transfer-orders-form"
      @refetch="refetch"
    />
  </div>

</template>
<script>
import TransferOrdersForm from '@/views/settings/orders/transfer-orders/components/TransferOrdersForm.vue'
import store from '@/store'
import transferOrdersModule from '@/store/settings/orders/transfer-orders'
import { onUnmounted } from '@vue/composition-api'
import Skeleton from './components/Skeleton.vue'
import config from './transferOrdersConfig'

export default {
  name: 'TransferOrders',
  components: {
    TransferOrdersForm,
    Skeleton,
  },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    this.$emit('updateMenu', 'settings-orders-transfer-orders')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['transfer-orders-form']) this.$refs['transfer-orders-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getTransferOrders`).then(res => {
        const { data } = res.data
        const obj = {}
        data.forEach(({ key, value }) => {
          obj[key] = value
        })
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, obj)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_ORDER_TRANSFER_ORDERS`, obj)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE, transferOrdersFields } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, transferOrdersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      transferOrdersFields,
    }
  },

}
</script>
