<template>
  <div>
    <h2
      class="font-weight-bolder font-medium-4"
    >
      {{ $t('Transfer Order Rules') }}
    </h2>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['transfer_orders_min_time', 'transfer_orders_schedule_after_warehouse_open', 'transfer_orders_schedule_before_warehouse_closes', 'transfer_orders_max_round_trips_in_day']"
          :key="field"
          style="height: 70px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px"
          >{{ transferOrdersFields[field].label }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div
          v-for="field in ['transfer_orders_turnaround', 'transfer_orders_order_scheduling', 'transfer_orders_order_picking']"
          :key="field"
          style="height: 70px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px"
          >{{ transferOrdersFields[field].placeholder }}</span>
          <b-skeleton
            height="30px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <h2
      class="font-weight-bolder font-medium-4 mt-2"
    >
      {{ $t('Templates') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['transfer_orders_templates_email', 'transfer_orders_templates_pdf']"
          :key="field"
          style="height: 170px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ transferOrdersFields[field].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow, BSkeleton } from 'bootstrap-vue'
import config from '../transferOrdersConfig'

export default {
  name: 'TransferOrdersSkeleton',
  components: { BRow, BCol, BSkeleton },
  setup() {
    const { transferOrdersFields } = config()

    return {
      transferOrdersFields,
    }
  },
}
</script>
