<template>
  <div>
    <h2
      class="font-weight-bolder font-medium-4"
    >
      {{ $t('Transfer Order Rules') }}
    </h2>
    <b-row>
      <b-col md="6">
        <component
          :is="transferOrdersFields[field].type"
          v-for="field in ['transfer_orders_min_time', 'transfer_orders_schedule_after_warehouse_open', 'transfer_orders_schedule_before_warehouse_closes', 'transfer_orders_max_round_trips_in_day']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="transferOrders[field]"
          v-bind="getProps(field, transferOrdersFields)"
          :is-editable="!$can(ACCESS_ABILITY_SETTINGS_ORDERS.action,ACCESS_ABILITY_SETTINGS_ORDERS.subject) || isEdit"
        />
      </b-col>
      <b-col md="6">
        <div
          v-for="field in ['transfer_orders_turnaround', 'transfer_orders_order_scheduling', 'transfer_orders_order_picking']"
          :key="field"
        >
          <label class="inputLabel">{{ $t(transferOrdersFields[field].placeholder) }}</label>
          <component
            :is="transferOrdersFields[field].type"
            :ref="`${field}_ref`"
            v-model="transferOrders[field]"
            v-bind="getProps(field, transferOrdersFields)"
            :is-editable="!$can(ACCESS_ABILITY_SETTINGS_ORDERS.action,ACCESS_ABILITY_SETTINGS_ORDERS.subject) || isEdit"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { getProps } from '@core/utils/utils'
import { BCol, BForm, BRow } from 'bootstrap-vue'
import config from '../transferOrdersConfig'

export default {
  name: 'TransferOrderRules',
  components: {
    BForm, BRow, BCol, ErrorAlert,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
    }
  },
  computed: {
    transferOrders() {
      return this.$store.state[this.MODULE_NAME].transferOrdersForm
    },
  },
  setup() {
    const { MODULE_NAME, transferOrdersFields, ACCESS_ABILITY_SETTINGS_ORDERS } = config()

    return {
      MODULE_NAME,
      transferOrdersFields,
      ACCESS_ABILITY_SETTINGS_ORDERS,
      getProps,
    }
  },
}
</script>
<style scoped>
</style>
