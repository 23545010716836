import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/order/transfer-order'

const Actions = crudActions(endpoint)
const getTransferOrders = (ctx, data) => axios.get(`${endpoint}`, data)
const updateTransferOrders = (ctx, data) => axios.post(`${endpoint}`, data)
export default {
  ...Actions,
  getTransferOrders,
  updateTransferOrders,
}
